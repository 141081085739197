import { defineStore } from "pinia";
import RestApiService from "@/api/restapi.service";
import { Pagination } from "@/types/Pagination";
import {
  SubscriptionPlan,
  SubscriptionPlanCollection,
} from "@/types/SubscriptionPlan";

export const useSubscriptionPlanStore = defineStore({
  id: "subscription-plans",
  state: () => ({
    errors: null,
    loading: false,
    subscriptionPlanList: {} as SubscriptionPlanCollection,
    subscriptionPlan: {} as SubscriptionPlan,
  }),
  getters: {},
  actions: {
    async fetchSubscriptionPlanList(
      pagination: Pagination
    ): Promise<Array<SubscriptionPlan> | Array<string>> {
      this.loading = true;
      const data = await RestApiService.get("/subscription-plans", pagination)
        .then(({ data }) => {
          this.subscriptionPlanList = data;
          return data;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
        });
      this.loading = false;
      return data;
    },

    async fetchSubscriptionPlan(id: string): Promise<SubscriptionPlan> {
      this.loading = true;
      const data = await RestApiService.get(`/subscription-plans/${id}`)
        .then(({ data }) => {
          this.subscriptionPlan = data;
          return data;
        })
        .catch(({ response }) => {
          this.errors = response.data.errors;
        });
      this.loading = false;
      return data;
    },

    async updateSubscriptionPlan(subscriptionPlan: SubscriptionPlan) {
      this.loading = true;
      const data = await RestApiService.update(
        "/subscription-plans",
        subscriptionPlan.id,
        subscriptionPlan
      );
      this.errors = data["errors"];
      this.loading = false;
      return data;
    },

    // async createFeature(feature: Feature): Promise<Feature> {
    //     this.loading = true;
    //     const data = await RestApiService.post(`/features`, feature)
    //         .then(({ data }) => {
    //             return data;
    //         })
    //         .catch(({ response }) => {
    //             this.errors = response.data.errors;
    //         });
    //     this.loading = false;
    //     return data;
    // },
    //
    //
    // async deleteFeature(id: number) {
    //     this.loading = true;
    //     const data = await RestApiService.delete(`/features/${id}`);
    //     this.errors = data["errors"];
    //     return data;
    // },
  },
});
